import React from "react";
import { Chat, Login, NotFound } from "../Layout";

const routes = [
  {
    path: "/",
    element: <NotFound />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/chat",
    element: <Chat />,
  },
];

export default routes;

