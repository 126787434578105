import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routing/routingpaths";
import { DefaultLayout, Middleware } from "./Layout";
import api from "./services/api";
import { Toaster } from "react-hot-toast";

function App() {
 
//   const handleBeforeUnload = (event) => {
//     localStorage.setItem("tabClose", true);
//   };

// //  useEffect(() => {
//    if (sessionStorage.getItem("reloaded") != null) {
//      console.log("page was reloaded");
//    } else {
//      console.log("page was not reloaded");
//         sessionStorage.setItem("reloaded", "no");
//            window.addEventListener("unload", handleBeforeUnload);


//    }

 
  return (
    <div>
      <BrowserRouter>
        <DefaultLayout>
          <Middleware>
            <Toaster />

            <Routes>
              {routes.map((route, routeIndex) => (
                <>
                  <Route {...route} key={routeIndex} />
                </>
              ))}
            </Routes>
          </Middleware>
        </DefaultLayout>
      </BrowserRouter>
    </div>
  );
}

export default App;
