import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Logo from "../../assets/logo.png"
import toast from "react-hot-toast";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await axios.post(
        "https://apiv2.ambientresearch.co/whatsapp/login",

        // "https://apiv2-sand.ambientresearch.co/whatsapp/login",
        {
          username: username,
          password: password,
        }
      );
      localStorage.setItem("userName", response?.data?.name);
      localStorage.setItem("token", response?.data?.token);
      console.log("Login successful:", response.data);
      navigate("/chat", { replace: true });
      setLoader(false);
      setUsername("");
      setPassword("");
    } catch (error) {
      setLoader(false);
      toast.error("Error", error, {
        position: "top-right",
      });
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen flex items-center justify-center">
      <div className="bg-white p-8 rounded shadow-md w-96 ">
        <img src={Logo} alt="Logo" className="mx-auto mb-4" />
        <h2 className="text-2xl font-bold mb-4 text-center">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              User Name
            </label>
            <input
              type="text" 
              id="email"
              className="w-full border rounded py-2 px-3"
              placeholder="Enter your username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-6">
            <label
              htmlFor="password"
              className="block text-gray-600 text-sm font-medium mb-2"
            >
              Password
            </label>
            <input
              type="password"
              id="password"
              className="w-full border rounded py-2 px-3"
              placeholder="Enter your password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="pb-color text-white font-medium py-2 px-4 rounded w-full"
          >
            {loader ? (
              <CircularProgress style={{ color: "#ffffff" }} size={25} />
            ) : (
              "Log In"
            )}
          </button>
        </form>

        {/* <div className="mt-4 text-center">
          <a href="/forgot-password" className="text-blue-500 hover:underline">
            Forgot your password?
          </a>
        </div> */}
      </div>
    </div>
  );
};

export default Login;
