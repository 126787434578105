// src/firebase.js

import firebase from "firebase/compat/app"; 
import "firebase/compat/firestore"; 
//testing
// const firebaseConfig = {
//   apiKey: "AIzaSyD-Ip63RDgNxD4vi86DiInP-6TYgF1a0aA",
//   authDomain: "whatsapp-chat-b870b.firebaseapp.com",
//   projectId: "whatsapp-chat-b870b",
//   storageBucket: "whatsapp-chat-b870b.appspot.com",
//   messagingSenderId: "747926816509",
//   appId: "1:747926816509:web:b6165dd97d5f0d70bfa094",
// };

//production

const firebaseConfig = {
  apiKey: "AIzaSyBgJW6YuW9C7VzobspUgBLWtGJTsDY5Bi4",
  authDomain: "ambient-whatsapp-chatroom.firebaseapp.com",
  projectId: "ambient-whatsapp-chatroom",
  storageBucket: "ambient-whatsapp-chatroom.appspot.com",
  messagingSenderId: "115305587657",
  appId: "1:115305587657:web:5f52cc42e8593bed6dbe18",
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const firestore = firebaseApp.firestore();

export default firestore;
