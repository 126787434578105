import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';

export  function Middleware({ children }) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      ["/", "/login"].includes(location.pathname) &&
      !location.pathname.split("/")[1] == "reset-password"
    ) {
      navigate("/login");
    } else if (["/", "/login"].includes(location.pathname)) {
      navigate("/login");
    }
  }, [location]);

  return <>{ children }</>;
}
