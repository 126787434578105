import axios from "axios";

//testing
// const baseURL = "https://apiv2-sand.ambientresearch.co/whatsapp";

//production
const baseURL = "https://apiv2.ambientresearch.co/whatsapp";


const instance = axios.create({
  baseURL,
  headers: {
    // "Content-Type": "application/json",
    // Other headers can be set here, but not the "auth-token"
  },
});

const api = {
  get: async (url, params) => {
    try {
      // Set the "auth-token" dynamically before making the request
      instance.defaults.headers.common["auth-token"] =
        localStorage.getItem("token");
             instance.defaults.headers.common["Content-Type"]="application/json";


      const response = await instance.get(url, { params });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  post: async (url, data,attach) => {
    try {
      instance.defaults.headers.common["auth-token"] =
        localStorage.getItem("token");
        if(attach)
        { instance.defaults.headers.common["Content-Type"] = attach;}

      const response = await instance.post(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  put: async (url, data) => {
    try {
      instance.defaults.headers.common["auth-token"] =
        localStorage.getItem("token");
             instance.defaults.headers.common["Content-Type"] =
               "application/json";

      const response = await instance.put(url, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delete: async (url) => {
    try {
      instance.defaults.headers.common["auth-token"] =
        localStorage.getItem("token");
             instance.defaults.headers.common["Content-Type"] =
               "application/json";

      const response = await instance.delete(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default api;
