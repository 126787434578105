
import styled from "styled-components";

export const ChatDiv = styled.div`
  .file-icon {
    svg {
      max-width: max-content;
      max-height: 4rem !important;
    }
  }

  .profile {
    background-color: #eeeeee;
  }

  .messages-div {
    background-color: #f7f7f7;
  }

  .name-initial {
    background-color: #63087d;
    position: relative;
  }
  .unread-badge {
    position: absolute;
    height: 15px;
    border-radius: 50%;
    font-weight: 700;
    width: 15px;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 10px;
    background: red;
    top: -2px;
    right: -2px;
  }
  .message-box {
    margin: 1rem 0;
  }

  footer {
    width: -webkit-fill-available;
  }
  .message-box-right {
    width: fit-content;
    background: #dcf8c6;
    padding: 1rem;
    margin-top: -0.8rem;
    margin-right: 0.5rem;
    ${"" /* margin: 1rem 0; */}
    border-radius: 20px 0 20px 20px;
  }
  .message-box-left {
    width: fit-content;
    padding: 1rem;
    background: #ffffff;
    margin-top: -0.8rem;
    margin-left: 0.5rem;
    ${"" /* margin: 1rem 0; */}
    border-radius: 0 20px 20px 20px;
  }
  .text-right {
    right: 0;
    display: flex;
    justify-content: end;
    margin-left: 5rem;
    margin-right: 1.5rem;

    .sender {
      display: flex;
      justify-content: flex-end;
      margin-right: -1rem;
    }

    .sender-initial {
      background-color: #3498db;
    }
    .double-ticks {
      display: flex;
      justify-content: flex-end;
    }
  }
  .text-left {
    display: flex;
    justify-content: start;
    left: 0;
    margin-right: 5rem;
    margin-left: 1.5rem;
    .sender {
      display: flex;
      justify-content: flex-start;
      margin-right: 1rem;
    }
    .sender-initial {
      background-color: #63087d;
    }
  }
  .status-icons {
    display: flex;
    align-items: center;
    color: #95a5a6; /* default gray color for delivered status */
  }

  .double-ticks-blue {
    color: #3498db; /* blue color for read status */
    margin-left: 5px;
  }

  .double-ticks-gray {
    margin-left: 5px;
  }
  .sender-name {
    font-size: 12px;
    font-weight: 700;
  }

  .sender-initial {
    width: 30px;
    height: 30px;
    ${"" /* background-color: #3498db; */}
    color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-right: 10px;
  }
  .message-date {
    color: gray;
    font-size: 8px;
  }
  .contact-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    cursor: pointer;
  }

  .contact-item:hover {
    background-color: #dad1fc;
  }

  .contact-item:active {
    background-color: #e0e0e0;
  }
`;

export const ModalDiv = styled.div`
  .css-d1gbib {
    height: auto !important;
  }
  .css-1fu7jd5-MuiButtonBase-root-MuiButton-root {
    background-color: #63087d !important;
  }

  .css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
    border: 1px solid #63087d9e !important;
    color: #63087d !important;
  }

  .css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
    border: 1px solid #63087d9e !important;
    color: #63087d !important;
    background-color: #63087d33 !important;
  }
`;