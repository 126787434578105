// AddNewClient.js
import React, { useEffect, useState } from "react";
import {
  Modal,
  Button,
  FormControl,
  InputLabel,
  TextField,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { Country, State } from "country-state-city";
import { Select } from "antd";
import api from "../../services/api";
import toast from "react-hot-toast";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
const AddNewClient = ({ isOpen, closeModal }) => {
   const [countries, setCountries] = useState([]);
   const [selectedCountry, setSelectedCountry] = useState("");
   const [states, setStates] = useState([]);
   const [selectedState, setSelectedState] = useState("");
     const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    mob: "",
    name: "",
    email: "",
    // state: "",
    // city: "",
    country: "",
    // language: "",
    comment: "",
  });
  const { Option } = Select;

  const handleChange = (field) => (event) => {
    if (field === "mob" && formData.countryCode) {
      setFormData({
        ...formData,
        mob:event.target.value,
      });
    } else {
      setFormData({ ...formData, [field]: event.target.value });
    }
  };


  const handleAddClient = async() => {
    console.log("formData", formData);
         setLoading(true);
       const updatedFormData = {
         ...formData,
         mob: formData.countryCode + formData.mob,
       };
    try {
      const res = await api.post("/createnewclient", updatedFormData);
      console.log("added client ", res.data);
            setLoading(false);
 toast.success("Client Added Successfully!", {
   position: "top-right",
 });
 setFormData({
  countryCode:"",
   mob: "",
   name: "",
   email: "",
  //  state: "",
  //  city: "",
   country: "",
  //  language: "",
   comment: "",
 });
      closeModal();

    } catch (error) {
            setLoading(false);
                      let errorMessage = "Something went wrong";

             if (error.response) {
               if (error.response.data && error.response.data.message) {
                 errorMessage = error.response.data.message;
               } else {
                 errorMessage = "Something went wrong";
               }
             }
   toast.error("Error: " + errorMessage, { position: "top-right" });

      console.error("Error:", error);
    }
    // closeModal();
  };
useEffect(() => {
  const allCountries = Country.getAllCountries();
  setCountries(allCountries);
}, []);

useEffect(() => {
  if (selectedCountry) {
    const statesOfSelectedCountry = State.getStatesOfCountry(selectedCountry);
    setStates(statesOfSelectedCountry);
  }
}, [selectedCountry]);
  return (
    <div style={{ padding: "20px" }}>
      <FormControl fullWidth style={{ marginTop: "10px" }}>
        <TextField
          placeholder="Enter Name"
          required
          id="outlined-required"
          label="Name"
          value={formData.name}
          onChange={handleChange("name")}
          size="small"
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: "10px", marginBottom: "2px" }}>
        <TextField
          placeholder="Enter Country Mobile Code"
          required
          id="outlined-required"
          label="Country Mobile Code"
          value={formData.countryCode}
          size="small"
          onChange={handleChange("countryCode")}
        />
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "10px", marginBottom: "2px" }}>
        <TextField
          placeholder="Enter Mobile"
          required
          id="outlined-required"
          label="Mobile"
          value={formData.mob}
          size="small"
          onChange={handleChange("mob")}
          disabled={!formData.countryCode}
        />
        {/* <label id="demo-simple-select-label">Mobile</label>

        <PhoneInput
          placeholder="Enter Mobile"
          required
          style={{
            border: "1px solid rgba(0, 0, 0, 0.23)",
            padding: "7px",
            borderRadius: "5px",
          }}
          value={formData.mob}
          onChange={(d) => {
            console.log(d?.replace(/\+/g, ""));
            
            setFormData({ ...formData, mob: d?.replace(/\+/g, "") });
          }}
        /> */}
      </FormControl>

      <FormControl fullWidth style={{ marginTop: "10px" }}>
        <TextField
          placeholder="Enter Email"
          id="outlined-required"
          label="Email"
          size="small"
          value={formData.email}
          onChange={handleChange("email")}
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: "10px" }}>
        <label id="demo-simple-select-label">Country</label>
        <Select
          size="large"
          showSearch
          value={selectedCountry}
          labelInValue
          placeholder="Select Country"
          style={{ width: "100%" }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          onChange={(data) => {
            console.log(data);
            setFormData({ ...formData, country: data?.value });

            setSelectedCountry(data.value);
          }}
        >
          {" "}
          {countries.map((country) => (
            <Option key={country.isoCode} value={country.isoCode}>
              {country.name}
            </Option>
          ))}
        </Select>
      </FormControl>

      {/* {selectedCountry && (
        <div>
          <FormControl fullWidth style={{ marginTop: "10px" }}>
            <label id="demo-simple-select-label">State</label>
            <Select
              size="large"
              showSearch
              value={selectedState}
              labelInValue
              placeholder="Select State"
              style={{ width: "100%" }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(data) => {
                console.log(data.value);
                setFormData({ ...formData, state: data?.value });

                setSelectedState(data?.value);
              }}
            >
              {" "}
              {states.map((state) => (
                <Option key={state.isoCode} value={state.isoCode}>
                  {state.name}
                </Option>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <FormControl fullWidth style={{ marginTop: "10px" }}>
        <TextField
          placeholder="Enter City"
          id="outlined-required"
          label="City"
          value={formData.city}
          size="small"
          onChange={handleChange("city")}
        />
      </FormControl>
      <FormControl fullWidth style={{ marginTop: "10px" }}>
        <TextField
          placeholder="Enter language"
          id="outlined-required"
          label="Language"
          value={formData.language}
          size="small"
          onChange={handleChange("language")}
        />
      </FormControl> */}
      <FormControl fullWidth style={{ marginTop: "10px" }}>
        <TextField
          placeholder="Enter Comment"
          id="outlined-required"
          label="Comment"
          value={formData.comment}
          size="small"
          onChange={handleChange("comment")}
        />
      </FormControl>
      <div style={{ marginTop: "20px", textAlign: "right" }}>
        <Button
          onClick={handleAddClient}
          variant="outlined"
          className="button-outlined"
        >
          {loading ? (
            <CircularProgress style={{ color: "#63087d" }} size={25} />
          ) : (
            "Add Client"
          )}
        </Button>
      </div>
    </div>
  );
};

export default AddNewClient;
