import React, { useEffect, useState } from "react";
import { ChatDiv } from "./chatStyle";
import ContactList from "./ContactList";
import Messages from "./Messages";
import firestore from "../../firebase";
import api from "../../services/api";
import { Route, useNavigate } from "react-router-dom";
import moment from "moment";
import { Box, Button, Modal, Typography } from "@mui/material";
import Logo from "../../assets/logo.png";

export function Chat() {
  const [selectedContact, setSelectedContact] = useState(null);

  const [contacts, setContacts] = useState([]);
  const [startContacts, setStartContacts] = useState([]);
  const [lastMessageInChat, setLastMessageInChat] = useState([]);
  const [message, setMessage] = useState([]);
  const [showNewChat, setShowNewChat] = useState(false);
  const navigate = useNavigate();
  const [showLogoutModal, setShowLogoutModal] = useState(false);

  const handleLogout = () => {
    setShowLogoutModal(true);
  };

  const confirmLogout = () => {
    localStorage.removeItem("userName");

    localStorage.removeItem("token");
    navigate("/login");
    setShowLogoutModal(false);
  };

  const UserName = localStorage.getItem("userName");
  const fetchContacts = async () => {
    try {
      const res = await api.get("/startedchats");
      console.log("setStartContacts", res);
      setStartContacts(res);
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchFireBaseContacts = async () => {
    // const DataPath = firestore.collection("chats");
    // try {
    //   const querySnapshot = await DataPath.get();

    //   const dataObj = [];

    //   querySnapshot.forEach((doc) => {
    //     dataObj.push(doc.data());
    //   });

    //   console.log("list", dataObj);

    //   // const contactsC = startContacts.filter((contactA) =>
    //   //   sortedData.some((contactB) => contactA.chat_id === contactB.mobile)
    //   // );
    //   const contactsC = startContacts.map((contactA) => {
    //     const matchingContactB = dataObj.find(
    //       (contactB) => contactA.chat_id === contactB.mobile
    //     );

    //     if (matchingContactB) {
    //       // Merge properties of contactA and matchingContactB
    //       // return { ...contactA, ...matchingContactB };
    //       return {
    //         ...matchingContactB,
    //         client_id: contactA.client_id,
    //         id: contactA.id,
    //       };
    //     }
    //     return contactA;
    //   });
    //   const sortedData = [...contactsC].sort((a, b) => {
    //     const dateA = new Date(a.lastUpdated);
    //     const dateB = new Date(b.lastUpdated);
    //     return dateB - dateA;
    //   });
    //   console.log("contactsC", contactsC);
    //   setContacts(sortedData);
    // } catch (error) {
    //   console.error("Error getting documents: ", error);
    // }
    const contactsArray = [];

    // Use Promise.all to wait for all async operations to complete
    await Promise.all(
      startContacts.map(async (contactA) => {
        try {
          const contactRef = firestore.collection("chats").doc(contactA.chat_id);
          const contactSnapshot = await contactRef.get();

          if (contactSnapshot.exists) {
            const contactData = contactSnapshot.data();

            // Add client_id and id to contactData
            contactData.client_id = contactA.client_id;
            contactData.id = contactA.id;

            console.log(contactData);
            contactsArray.push(contactData);
          } else {
            console.log("Contact not found");
          }
        } catch (error) {
          console.error("Error fetching contact:", error);
        }
      })
    );

    // Log the first object in the contactsArray
    console.log("First object in contactsArray:", contactsArray);
    const sortedData = [...contactsArray].sort((a, b) => {
      const dateA = new Date(a.lastUpdated);
      const dateB = new Date(b.lastUpdated);
      return dateB - dateA;
    });
    setContacts(sortedData);
  };
  const fetchChats = async (mob) => {
    console.log("selectedContact.mobile", mob);
    //  if (!mob) {
    //    console.error("Mobile number is undefined or null.");
    //    return;
    //  }
    const chatRef = firestore
      .collection("chats")
      .doc(mob)
      .collection("messages")
      .where("mob", "==", mob)
      .onSnapshot(function (querySnapshot) {
        var chat = [];
        querySnapshot.forEach(function (doc) {
          chat.push(doc.data());
        });
        chat.sort((a, b) => a.timestamp - b.timestamp);
        if (chat?.length > 0) {
          //fetchContacts();
          setMessage(chat);
          const lastMessage = chat[chat?.length - 1];
          setLastMessageInChat(lastMessage);
          const isLastMessageFromUser = lastMessage?.sender === UserName;
          const isMoreThan24Hours =
            moment().diff(moment(lastMessage?.timestamp * 1000), "hours") > 24;
          setShowNewChat(isMoreThan24Hours && isLastMessageFromUser);
        }

        console.log("chat", chat);
        // console.log("isLastMessageFromUser....", isLastMessageFromUser);
        // console.log("isMoreThan24Hours....", isMoreThan24Hours);
      });
  };

  useEffect(() => {
    if (startContacts.length > 0) {
      fetchFireBaseContacts();
    }
  }, [startContacts]);

  useEffect(() => {
    if (contacts && selectedContact) {
      if (message[0].mob != selectedContact.mobile) {
        console.log(".........fetchChats", selectedContact);
        fetchChats(selectedContact?.mobile);
      }
    }
  }, [message]);

  const handleContactSelect = (contact, con) => {
    console.log("contact", contact, "    rrrrrrrrrrr", con);
    console.log("contacts", contacts);

    if (con === "only-mob") {
      const filteredContacts = contacts.filter((c) => c.mobile === contact);
      console.log("filteredContacts", filteredContacts);
      setSelectedContact(filteredContacts[0]);
    } else {
      setSelectedContact(contact);
    }
  };

  const setMessageRead = async (selectedClient) => {
    let data = {
      mob: selectedClient,
    };
    try {
      const res = await api.post("/markmessageread", data);
      console.log("setClientList", res.data);
      fetchContacts();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <ChatDiv>
      <div className="flex h-screen ">
        {/* Contact List */}
        <div className="w-1/4 ">
          <div className="flex  p-2 profile ">
            <img
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSg96alC8RLgh_RHfSce-M_jfQjg_V4o7A2ppUxeJLIRZk8-elRKm7zoE5jl1sg37_j78c&usqp=CAU" // Replace with the URL of the user's profile picture
              alt="User Profile"
              className="w-12 h-12 rounded-full"
            />
            <div className="flex-col ml-2 justify-start align-middle">
              <div className="font-bold text-base mt-3">{UserName}</div>
              {/* <div className="text-gray-400 text-sm">User Number</div> */}
            </div>
          </div>

          <div className="p-4">
            {/* <h1 className="text-2xl font-bold mb-4">Contacts</h1> */}
            <ContactList
              onContactSelect={handleContactSelect}
              setSelectedContact={setSelectedContact}
              setMessageRead={setMessageRead}
              selectedContact={selectedContact}
              contacts={contacts}
              setContacts={setContacts}
              lastMessageInChat={lastMessageInChat}
              fetchContacts={fetchContacts}
              fetchChats={fetchChats}
            />
          </div>
        </div>

        {/* Messages Section */}
        <div
          className="w-3/4 relative messages-div"
          style={{ background: "#d3c5e199" }}
        >
          <div
            className={`flex p-3 pl-4 border-l-2 profile  ${
              selectedContact ? "justify-between" : "justify-end"
            }`}
            style={{
              height: "4rem",
              width: "-webkit-fill-available",
              position: "fixed",
            }}
          >
            {selectedContact && (
              <div className="flex  profile">
                <div className="h-10 w-10 name-initial rounded-full text-white flex items-center justify-center">
                  {selectedContact?.name[0].toUpperCase() ||
                    selectedContact?.chat_id}
                </div>
                <div className="flex ml-2 justify-start align-middle flex-col">
                  <div className="text-lg  font-bold">
                    {selectedContact?.name}
                  </div>
                  <div className="text-gray-500 text-xs">
                    {selectedContact.mobile}
                  </div>
                </div>
              </div>
            )}
            <div className="flex items-center ml-2 justify-end">
              <div className="text-gray-400 text-sm">
                <Button
                  variant="contained"
                  fullWidth
                  className="p-2 m-2 button-filled"
                  onClick={handleLogout}
                >
                  Log out
                </Button>
              </div>
            </div>
          </div>

          {selectedContact ? (
            <div className=" ml-1" style={{ padding: "0.5rem" }}>
              {/* <h1 className="text-2xl font-bold mb-4">Messages</h1> */}
              {selectedContact && (
                <Messages
                  UserName={UserName}
                  selectedContact={selectedContact}
                  setMessageRead={setMessageRead}
                  fetchContacts={fetchContacts}
                  fetchChats={fetchChats}
                  message={message}
                  showNewChat={showNewChat}
                />
              )}
            </div>
          ) : (
            <div
              className=" p-4 ml-1 h-5 flex justify-center align-middle text-center  mt-12"
              style={{ minHeight: "35rem", alignItems: "center" }}
            >
              <div className="text-center">
                <img src={Logo} alt="Logo" className="mx-auto mb-4" />
                <div style={{ maxWidth: "30rem", color: "cadetblue" }}>
                  <h1 className="text-xl font-bold  ">
                    Select Contact or Start New Chat With Venza Whatsapp
                    Messenger
                  </h1>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        open={showLogoutModal}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => setShowLogoutModal(false)}
      >
        <Box sx={{ width: 300, bgcolor: "background.paper", p: 2 }}>
          <Typography sx={{ mb: 2 }}>
            Are you sure you want to log out?
          </Typography>
          <div className="flex justify-between align-middle">
            <Button
              onClick={() => setShowLogoutModal(false)}
              sx={{ marginRight: 2 }}
              className="p-2 m-2 button-outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={confirmLogout}
              variant="contained"
              className="p-2 m-2 button-filled"
            >
              Logout
            </Button>
          </div>
        </Box>
      </Modal>
    </ChatDiv>
  );
}
