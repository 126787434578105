import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { RenderIf } from "./renderif";

export function DefaultLayout({ children }) {
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const checkResetPassword = () => {
    if (location.pathname.split("/").includes("reset-password")) {
      return false;
    } else if (["/", "/login"].includes(location.pathname)) {
      return false;
    } else if (isSidebar) {
      return isSidebar;
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("userId") && !localStorage.getItem("token") && !localStorage.getItem("role")) {
      navigate('/')
    }
  }, [])

  return (
    <>
      <div className="wrapper">
        
        {/* <RenderIf isTrue={checkResetPassword()}>
          <Navbar
            onCollapse={() => setIsSidebar((prev) => !prev)}
            isCollapse={checkResetPassword()}
          />
        </RenderIf>
        <RenderIf isTrue={checkResetPassword()}>
          <Sidebar />
        </RenderIf> */}
       { ["/", "/login"].includes(location.pathname)?<div>{children}</div>:
        <main isCollapse={checkResetPassword()}>{children}</main>}
        {/* <RenderIf isTrue={checkResetPassword()}>

          <Footer /></RenderIf> */}
      </div>
    </>
  );
}
