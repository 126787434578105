import React, { useEffect, useState } from "react";
import NewChatModal from "./NewChatModal";
import { Button } from "@mui/material";
import firestore from "../../firebase";
import { Modal } from "antd";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
const ContactList = ({
  selectedContact,
  onContactSelect,
  fetchContacts,
  contacts,
  setContacts,
  setMessageRead,
  lastMessageInChat,
  fetchChats,
  setSelectedContact,
}) => {
  const [searchContacts, setSearchContacts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setTimeout(() => {
      setIsModalOpen(false);
    }, 3000);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filteredContacts = contacts.filter((contact) =>
      contact.name.toLowerCase().includes(searchTerm)
    );
    setSearchContacts(filteredContacts);
  };
  useEffect(() => {
    console.log("fetch Contact", localStorage.getItem("token"));
    fetchContacts();
  }, []);
  useEffect(() => {
    setSearchContacts(contacts);
  }, [contacts]);

  useEffect(() => {
    if (lastMessageInChat?.mob == selectedContact?.mobile) {
      setMessageRead(selectedContact?.mobile);
    }
  }, [lastMessageInChat]);

  return (
    <div>
      <div className="mb-3">
        <Button
          variant="contained"
          onClick={openModal}
          fullWidth
          className="p-2 m-2 button-filled"
        >
          Start New Chat
        </Button>
      </div>
      <input
        type="text"
        placeholder="Search contacts"
        className="w-full p-2 border border-gray-300 rounded-md"
        onChange={handleSearch}
      />
      {searchContacts?.length > 0 ? (
        <ul
          className="mt-4 overflow-y-auto scrollbar-thin"
          style={{ maxHeight: "70vh" }}
        >
          {searchContacts.map((contact) => (
            <li
              key={contact?.id}
              className={`flex items-center space-x-2 p-2 mb-2  justify-between hover:bg-gray-100 border-b-2 ${
                selectedContact?.mobile == contact?.mobile ? "bg-gray-200 " : ""
              } cursor-pointer`}
              style={{ boxShadow: "lightblue -2px 4px 6px" }}
              onClick={() => onContactSelect(contact, "")}
            >
              <div className="flex">
                <div className="h-10 w-10 name-initial rounded-full text-white flex items-center justify-center relative">
                  {contact?.name
                    ? contact?.name[0]?.toUpperCase()
                    : contact?.mobile || contact?.chat_id[0]}
                  {/* {getInitials(contact?.name?.toUpperCase())} */}
                  {contact.isUnread && (
                    <div className="unread-badge">{contact?.unreadMsgNos}</div>
                  )}
                </div>
                <div className="pl-1">
                  <p className="font-semibold">
                    {contact?.name || contact?.mobile || contact?.chat_id}
                  </p>
                  <p className="text-gray-600 text-sm">{contact?.mobile}</p>
                </div>
              </div>
              <div>
                <a
                  href={`http://crm.ambientresearch.co/crm/client/view/${contact?.client_id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-blue-500"
                >
                  <OpenInNewIcon />
                </a>
              </div>
            </li>
          ))}
        </ul>
      ) : (
        ""
      )}
      <Modal
        open={isModalOpen}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={false}
      >
      
        <NewChatModal
          isOpen={isModalOpen}
          closeModal={handleCancel}
          fetchContacts={fetchContacts}
          fetchChats={fetchChats}
          setSelectedContact={setSelectedContact}
          onContactSelect={onContactSelect}
        />
      </Modal>
    </div>
  );
};

export default ContactList;
