// NewChatModal.js
import React, { useState, useEffect } from "react";
import { Modal, Select, Spin } from "antd";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import api from "../../services/api";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { ModalDiv } from "./chatStyle";
import toast from "react-hot-toast";
import AddNewClient from "./AddNewClient";

const NewChatModal = ({
  isOpen,
  closeModal,
  fetchContacts,
  selectedContact,
  isOnlyTemplet,
  fetchChats,
  onContactSelect,
}) => {
  const [clientList, setClientList] = useState([]);
  const [templateList, setTemplateList] = useState([]);
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedTemplate, setSelectedTemplate] = useState("");
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);
    // const [isNewClientModalOpen, setNewClientModalOpen] = useState(false);

  const { Option } = Select;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    height: 300,
    bgcolor: "background.paper",
    //   border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };

  const getClient = async (input) => {
    try {
      setLoading(true);
      const res = await api.get(`/clients?search=${input}`);
      console.log("setClientList", res.data);
      setClientList(res.data);
    } catch (error) {
      console.error("Error:", error);
            setClientList([]);

    } finally {
      setLoading(false);
    }
  };
  const getTemplete = async () => {
    try {
      const res = await api.get("/template");
      console.log("report", res.data);
      setTemplateList(res.data);
    } catch (error) {
      console.error("Error:", error);
    }
  };
const handleOk =()=>{
  console.log("ok click");
}
  const startChat = async () => {
    setLoader(true);
    let data = {
      clientId: selectedContact ? selectedContact.mobile : selectedClient,
      templateId: selectedTemplate,
    };
    try {
      const res = await api.post("/startchat", data);
      console.log("setClientList", res);
      setSelectedTemplate("");
      setSelectedClient("");
      fetchContacts();
      if (selectedClient) {
        onContactSelect(selectedClient, "only-mob");
      } else {
        fetchChats(selectedContact?.mobile);
      }
      closeModal();
      setLoader(false);
    } catch (error) {
      setLoader(false);
          let errorMessage = "Something went wrong";

  if (error.response && error.response.status === 500) {
    if (error.response.data && error.response.data.message) {
      errorMessage = error.response.data.message;
    } else {
      errorMessage = "Internal Server Error";
    }
  }
   if (error.response && error.response.status === 400) {
     if (error.response.data && error.response.data.message) {
       errorMessage = error.response.data.message.clientId
         ? error.response.data.message.clientId
         : error.response.data.message.templateId;
     } else {
       errorMessage = "Something went wrong";
     }
   }

  console.error("API Error:", error.message || error.response?.data || error);
  toast.error("Error: " + errorMessage, { position: "top-right" });
      console.error("Error:", error?.response?.data);
            console.error("Error:", error);

    }
  };

  useEffect(() => {
    if (isOpen) {
      getClient("");
      getTemplete();
    }
  }, []);
  useEffect(() => {
    console.log("uss");
    if (closeModal) {
      getClient('');
      setSelectedTemplate('');
      setSelectedClient('');
   
    }
  }, [closeModal]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  // const handleOk = () => {
  //   setIsModalOpen(false);
  // };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <ModalDiv>
      <Box sx={style}>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          className="flex justify-between"
        >
          {isOnlyTemplet ? "Select Template" : "Start New Chat"}
          <Button className=" mt-3 button-outlined" onClick={closeModal}>
            <CloseIcon />
          </Button>
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }}>
          {!isOnlyTemplet && (
            <div className="mt-4">
              <FormControl fullWidth size="small">
                <label>Select Client</label>

                <Select
                  disabled={selectedContact}
                  size="large"
                  showSearch
                  onSearch={(data) => getClient(data)}
                  labelInValue
                  placeholder="Select Client"
                  style={{ width: "100%" }}
                  value={selectedClient||''}
                  loading={loading}
                  onChange={(data) => {
                    console.log("selected", data);
                    if (data) {
                      setSelectedClient(data?.value || null);
                    } else {
                      getClient("");
                    }
                  }}
                  notFoundContent={
                    <div className="ap-w100 flex justify-center align-middle ">
                      <div className="flex flex-col ">
                        <p className=" align-middle flex justify-center p-2">
                          No Data Found !
                        </p>
                        <p className="p-2">
                          <Button
                            className=" mt-3 button-outlined"
                            onClick={() => {
                              closeModal();
                              showModal();
                            }}
                          >
                            <AddIcon /> Add New Client
                          </Button>
                        </p>
                      </div>
                    </div>
                  }
                  allowClear
                  filterOption={false}
                >
                  {clientList.map((client, index) => (
                    <Option value={client.id} index={index}>
                      {`${client?.name}  (${client?.id})`}
                    </Option>
                  ))}
                </Select>
              </FormControl>
            </div>
          )}
          <div className="mt-4">
            <FormControl fullWidth size="small">
              <label>Select Template</label>
              <Select
                showSearch
                size="large"
                labelInValue
                placeholder="Select Template"
                value={selectedTemplate||''}
                onChange={(data) => {
                  setSelectedTemplate(data?.value);
                }}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                allowClear
                dropdownRender={(menu) => <div>{menu}</div>}
              >
                {templateList?.map((item) => (
                  <Option value={item?.id} key={item?.id}>
                    <div className="flex justify-between">
                      <span>{item?.title}</span>
                      <span>{item?.language}</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="mt-6 flex justify-center align-middle">
            <Button
              onClick={startChat}
              variant="outlined"
              className="button-outlined"
            >
              {loader ? (
                <CircularProgress style={{ color: "#63087d" }} size={25} />
              ) : isOnlyTemplet ? (
                "Send"
              ) : (
                "Start Chat"
              )}
            </Button>
          </div>
        </Typography>
      </Box>

      <Modal
        title="Add New Client"
        centered
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        // closeIcon={false}
      >
        <AddNewClient isOpen={isModalOpen} closeModal={handleCancel} />
      </Modal>
    </ModalDiv>
  );
};

export default NewChatModal;
