import React, { useState, useEffect, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import CloseIcon from "@mui/icons-material/Close";
import StyleIcon from "@mui/icons-material/Style";
import moment from "moment";
import "moment-timezone";
import api from "../../services/api";
import { Button, CircularProgress } from "@mui/material";
import NewChatModal from "./NewChatModal";
import { Modal } from "antd";
import toast from "react-hot-toast";
import { AttachFile } from "@mui/icons-material";
import { FileIcon } from "react-file-icon";

function Messages({
  UserName,
  selectedContact,
  setMessageRead,
  fetchContacts,
  fetchChats,
  message,
  showNewChat,
}) {
  const [loader, setLoader] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const messagesContainerRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customer, setCustomer] = useState(selectedContact);
  const [isOnlyTemplet, setIsOnlyTemplet] = useState(false);
  const [attachedFile, setAttachedFile] = useState(null);
  const [filePreview, setFilePreview] = useState(null);
  let fileInputRef;
  useEffect(() => {
    if (selectedContact) {
      console.log("test-message", selectedContact);
      setCustomer(selectedContact);
    }
  }, [selectedContact]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setTimeout(() => {
      setIsModalOpen(false);
    }, 3000);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setAttachedFile(file);
      // setNewMessage(file.name);
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          setFilePreview(reader.result);
        };
      } else {
        setFilePreview(null);
      }
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (customer.mobile) {
      fetchChats(customer.mobile);
      // setMessageRead(customer.mobile);
    }
  }, [customer.mobile]);

  const handleRemoveFile = () => {
    setAttachedFile(null);
    setFilePreview(null);
    if (fileInputRef) {
      fileInputRef.value = null;
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "" && !attachedFile) return;
    setLoader(true);
    if (attachedFile) {
      console.log("selected file", attachedFile);
      let data = {
        mobileNo: customer.mobile,
        // attachment:attachedFile,
        caption: newMessage,
      };
      console.log("check file", data);
      var formData = new FormData();
      for (var i in data) {
        formData.append(i, data[i]);
      }
      formData.append("attachment", attachedFile);

      try {
        const res = await api.post("/sendattachment", formData, {
          "Content-Type": "application/x-www-form-urlencoded",
        });
        console.log("setClientList", res.data);
        setLoader(false);
        fetchChats(selectedContact.mobile);
        setNewMessage("");
        setAttachedFile(null);
        setFilePreview(null);

        // setClientList(res.data);
      } catch (error) {
        setLoader(false);
        toast.error("Error", error, {
          position: "top-right",
        });
        console.error("Error:", error);
      }
    } else {
      let data = {
        mobileNo: customer.mobile,
        message: newMessage,
      };
      try {
        const res = await api.post("/sendmessage", data);
        console.log("setClientList", res.data);
        setLoader(false);
        fetchChats(selectedContact.mobile);
        setNewMessage("");
        // setClientList(res.data);
      } catch (error) {
        setLoader(false);
        toast.error("Error", error, {
          position: "top-right",
        });
        console.error("Error:", error);
      }
    }
  };
  const isImage = (url) => {
    const imageExtensions = [
      ".jpg",
      ".jpeg",
      ".png",
      ".gif",
      ".bmp",
      ".tiff",
      ".webp",
      ".svg",
      ".ico",
      ".jfif",
      ".pjpeg",
      ".pjp",
    ];
    const lowercasedUrl = url.toLowerCase();

    return imageExtensions.some((ext) => lowercasedUrl.endsWith(ext));
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSendMessage();
    }
  };
  const getSenderInitial = (sender) => {
    return sender ? sender.charAt(0).toUpperCase() : "";
  };

  useEffect(() => {
    messagesContainerRef.current.scrollTop =
      messagesContainerRef.current.scrollHeight;
  }, [message]);

  return (
    <div className="flex flex-col h-full">
      <div
        ref={messagesContainerRef}
        className="overflow-y-auto mb-1 scrollbar-thin mt-12"
        style={{ height: "80vh" }}
      >
        {message.length > 0 &&
          message.map((message, index) => (
            <div key={index} className={`text-sm message-box `}>
              {customer?.mobile == message?.mob ? (
                <div
                  className={`${
                    message.sender === UserName ? "text-right" : "text-left"
                  }  `}
                >
                  <div>
                    <div className={`sender`}>
                      <div className="sender-initial">
                        {getSenderInitial(message.sender)}
                      </div>
                    </div>
                    <div
                      className={`${
                        message.sender === UserName
                          ? "message-box-right"
                          : "message-box-left"
                      }  `}
                    >
                      <div className="sender-name">{message.sender}</div>

                      <div className="message-content">
                        {message?.message_type == "A" ? (
                          <>
                            {message?.attachment_link && (
                              <>
                                {isImage(message?.attachment_link) ? (
                                  <>
                                    <a
                                      href={message?.attachment_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className=" text-blue-500"
                                    >
                                      <img
                                        src={message?.attachment_link}
                                        alt="File Preview"
                                        className="max-w-full mt-2 max-h-32"
                                      />
                                    </a>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: message?.message,
                                      }}
                                    />

                                    {/* {message?.message} */}
                                  </>
                                ) : (
                                  <>
                                    <a
                                      href={message?.attachment_link}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className=" text-blue-500"
                                    >
                                      <div className="mt-2 max-h-24 file-icon">
                                        <FileIcon
                                          extension={
                                            message?.attachment_link
                                              .split(".")
                                              .pop()
                                              .toLowerCase() || "docx"
                                          }
                                          foldColor={"#ca87de"}
                                          labelColor={"#63087d"}
                                        />
                                      </div>

                                      {/* {message?.attachment_link} */}
                                    </a>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: message?.message,
                                      }}
                                    />
                                    {/* {message?.message} */}
                                  </>
                                )}
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: message?.message,
                              }}
                            />
                            {/* {message.message} */}
                          </>
                        )}
                      </div>
                      <div className="flex justify-end">
                        <div className="message-date">
                          {moment(new Date(message.timestamp * 1000)).format(
                            "MMM D, YYYY h:mm A"
                          )}
                        </div>
                        {message.sender === UserName ? (
                          <div className="status-icons double-ticks ">
                            {message.status === "read" ? (
                              <span className="double-ticks-blue">
                                &#10004;&#10004;
                              </span>
                            ) : (
                              <span className="double-ticks-gray">
                                &#10004;&#10004;
                              </span>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
      </div>
      <footer className="absolute bottom-3 p-1">
        {!showNewChat ? (
          <div
            className="flex align-middle justify-center "
            style={{ alignItems: "center" }}
          >
            <div className="w-full p-1 pl-2 pr-2 border border-gray-300 bg-white rounded-md mr-2 max-h-48 overflow-y-auto overflow-x-hidden">
              {attachedFile ? (
                <div className=" max-w-fit">
                  <CloseIcon
                    onClick={handleRemoveFile}
                    className="float-right cursor-pointer"
                  />{" "}
                  {filePreview ? (
                    <img
                      src={filePreview}
                      alt="File Preview"
                      className="max-w-full max-h-20 mt-1"
                    />
                  ) : (
                    <>
                      {" "}
                      <div className="mt-1 max-h-24 file-icon">
                        <FileIcon
                          extension={
                            attachedFile.name.split(".").pop().toLowerCase() ||
                            "docx"
                          }
                          foldColor={"#ca87de"}
                          labelColor={"#63087d"}
                        />
                      </div>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
              <input
                type="text"
                className="w-full p-2 border-gray-300 rounded-md mr-2 border-0"
                placeholder="Type a message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyPress={handleKeyPress}
              />
            </div>
            <div>
              <button
                variant="text"
                className="name-initial text-white p-2 rounded-md flex justify-center align-middle"
                onClick={handleSendMessage}
              >
                {loader ? (
                  <>
                    <CircularProgress style={{ color: "#ffffff" }} size={25} />
                  </>
                ) : (
                  <SendIcon />
                )}
              </button>
            </div>
            <div>
              <input
                type="file"
                id="fileInput"
                className="hidden"
                onChange={handleFileChange}
                ref={(input) => (fileInputRef = input)}
              />
              <button
                variant="text"
                className="name-initial text-white p-2 ml-2 rounded-md flex justify-center align-middle"
                onClick={() => fileInputRef.click()}
                disabled={loader}
              >
                <AttachFile />
              </button>
            </div>
            <div>
              <button
                variant="text"
                className="name-initial text-white p-2 ml-2 rounded-md flex justify-center align-middle"
                onClick={() => {
                  openModal();
                  setIsOnlyTemplet(true);
                }}
                disabled={loader}
              >
                <StyleIcon />
              </button>
            </div>
          </div>
        ) : (
          <div className="flex">
            {" "}
            <Button
              variant="contained"
              onClick={openModal}
              fullWidth
              className="p-2 m-2 button-filled"
            >
              Start New Chat
            </Button>
          </div>
        )}
      </footer>
      <Modal
        open={isModalOpen}
        centered
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closeIcon={false}
      >
        <NewChatModal
          isOnlyTemplet={isOnlyTemplet}
          isOpen={isModalOpen}
          closeModal={handleCancel}
          fetchContacts={fetchContacts}
          selectedContact={customer}
          fetchChats={fetchChats}
        />
      </Modal>
    </div>
  );
}

export default Messages;
